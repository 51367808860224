
<template>
  <div
    v-if="model"
    class="layout-container customer-service-card-block px-0 md:px-56 mb-48 sm:mb-64 min-h-[100px] md:!min-h-[300px]"
    :class="{
      'use-slider': model.useSliderOnMobile
    }"
  >
    <div v-if="isEpiEdit" class="mb-24">
      <div
        v-epi-edit="'BackgroundColor'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Change background color
      </div>
      <div
        v-epi-edit="'UseSliderOnMobile'"
        class="btn btn--secondary btn--sm mr-16 rounded-none px-12 epi-edit-button"
      >
        Use slider in mobile
      </div>
    </div>
    <client-only>
      <swiper
        v-epi-edit="'Items'"
        observer
        :space-between="isMobile ? 12 : 24"
        :no-swiping="true"
        :no-swiping-class="'swiper-no-swiping'"
        :slides-per-view="!isMobile ? 3 : 'auto'"
        :class="{
          'no-swiper-mobile swiper-no-swiping' : (!model.useSliderOnMobile && isMobile) || !isMobile,
          'swiper-mobile' : model.useSliderOnMobile,
        }"
        class="!px-24 md:!px-0 swiper-customer-service-cards"
      >
        <swiper-slide
          v-for="(customerServiceCardItem, index) in model.items"
          :key="index"
          class="!h-auto"
        >
          <CustomerServiceCardItem
            :model="customerServiceCardItem"
            :background-color="model.backgroundColor"
          />
        </swiper-slide>
      </swiper>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import type { CustomerServiceCardsBlock } from '~/content-types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { usePageContentStore } from '~/store/pageContent';
const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);
const { isEpiEdit } = storeToRefs(usePageContentStore());

defineProps<{
  model?: CustomerServiceCardsBlock;
}>();
</script>

<style lang="postcss">
.swiper-customer-service-cards.swiper-mobile .swiper-wrapper .swiper-slide {
  @media screen and (max-width: 767px) {
    width: 80% !important;
  }
}

.swiper-customer-service-cards.swiper-no-swiping .swiper-wrapper .swiper-slide {
  @apply !mb-16;
}

.swiper-customer-service-cards .swiper-wrapper {
  @apply md:grid md:grid-cols-3 md:gap-x-24 md:gap-y-8;

  .swiper-slide {
    @apply md:!mx-0;
  }
}
.blockAreaRight {
  .swiper {
    @apply px-0 -mx-24 md:mx-0;
  }

  .no-swiper-mobile {
    .swiper-wrapper {
      @apply block;
  
      .swiper-slide {
        @apply !w-full !mb-16;
      }
    }
  }
}
</style>
